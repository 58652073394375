import request from '@/utils/request'

export const reqGetNewsList = (params) => {
  return request({
    url: '/openApi/article_lost_word',
    method: 'get',
    params
  })
}
export const reqGetContent = (id) => {
  return request({
    url: `/openApi/article_lost_word/${id}`,
    method: 'get'
  })
}
export const reqGetLinks = (params) => {
  return request({
    url: '/openApi/links',
    method: 'get',
    params
  })
}
